import React from "react"
import { Box, Heading, Container, Text } from "@theme-ui/components"
import { MagicLink } from "../../utils/magicLink"

const TitleAndDescription = ({ block }) => {
  const { title, description, link } = block
  return (
    <Box sx={{ mb: [6,9] }}>
      <Container sx={{}}>
        <Box
          sx={{
            maxWidth: "900px",
            m: ["auto"],
          }}
        >
          <Heading
            as="div"
            sx={{
              "h1,h2": {
                fontSize: [5,6,7, 8],
                fontWeight: "400",
                em: {
                  color: "dark",
                },
                textAlign: "center",
              },
            }}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <Text
            as="div"
            variant="p"
            sx={{
              p: {
                fontSize: [3,4, 5],
              },
            }}
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            {link && <MagicLink item={link} variant="links.badge.full" />}
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default TitleAndDescription
