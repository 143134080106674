import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Box, Container, Heading, Grid, Flex } from "@theme-ui/components"
import PlaylistThumb from "../playlistThumb"
import { i18nContext } from "../../context/i18nContext"
import { OutboundLink } from "../link"
const SponsorCollection = ({ title, sponsors }) => {
  console.log(sponsors)
  return (
    <i18nContext.Consumer>
      {t => (
        <>
          <Container sx={{ py: [4, 4, 6] }}>
            {title && (
              <Heading as="h1" variant="h1" sx={{ mb: [4], mt: [0] }}>
                {title}
              </Heading>
            )}
          </Container>
          {sponsors.map((sponsor, index) => (
            <Box sx={{ backgroundColor: index % 2 === 0 ? "#f6f6f6" : "white" }}>
              <Container key={sponsor.id} sx={{ py: [6] }}>
                <Grid
                  columns={["1fr 4fr", "1fr 3fr", "1fr 3fr"]}
                  gap={["32px", "64px", "64px"]}
                  key={sponsor.id}
                >
                  <Flex
                    sx={{
                      mb: 3,
                      ".gatsby-image-wrapper": { width: "50%" },
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {sponsor.image && (
                      <GatsbyImage
                        image={sponsor.image.gatsbyImageData}
                        alt={sponsor.name}
                      />
                    )}
                  </Flex>
                  <Grid
                    columns={[1, 1, "1fr 1fr"]}
                    gap={["32px", "32px", "64px"]}
                  >
                    <Box>
                      {sponsor.name && (
                        <Heading
                          as="h3"
                          variant="h3"
                          sx={{
                            mb: [4],
                            mt: [0],
                            color: "text",
                            fontWeight: 500,
                          }}
                        >
                          {sponsor.name}
                        </Heading>
                      )}

                      {sponsor.description && (
                        <Box
                          sx={{ mb: 3 }}
                          dangerouslySetInnerHTML={{
                            __html: sponsor.description,
                          }}
                        />
                      )}

                      {sponsor.url && (
                        <OutboundLink
                          href={sponsor.url}
                          sx={{
                            mt: [3],
                            display: "inline-block",
                            backgroundColor: "dark",
                            color: "white",
                            px: [4],
                            py: [3],
                            textDecoration: "none",
                            borderRadius: "full",
                            textTransform: "uppercase",
                            fontSize: [1],
                            fontWeight: 700,
                            transition: "background-color 0.3s ease",
                            "&:hover": {
                              backgroundColor: "primary",
                            },
                          }}
                        >
                          {t.visitWebsite}
                        </OutboundLink>
                      )}
                    </Box>
                    {sponsor.playlist && (
                      <Box sx={{ mt: [3] }}>
                        <PlaylistThumb playlist={sponsor.playlist} />
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Container>
            </Box>
          ))}
        </>
      )}
    </i18nContext.Consumer>
  )
}

export default SponsorCollection
