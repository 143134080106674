import React, { useState } from "react"
import {
  Box,
  Flex,
  Grid,
  Heading,
  Text,
  Image,
  Container,
  Button,
} from "@theme-ui/components"
import { useBreakpointIndex } from "@theme-ui/match-media"

import { i18nContext } from "../../context/i18nContext"
import ReactModal from "react-modal"

const PastEvents = ({ events }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [currentEmbed, setCurrentEmbed] = useState("")
  const breakpointIndex = useBreakpointIndex()

  const openModal = embedCode => {
    setCurrentEmbed(embedCode)
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  const customStyles = {
    content: {
      padding: "0px",
      position: "absolute",
      inset: "0",
      width: breakpointIndex > 1 ? "50%" : "90%",
      display: "inline-table",
      // height: "auto",
      top: "50%",
      left: "50%",
      // right: "auto",
      // bottom: "auto",
      marginLeft: breakpointIndex > 1 ? "25%" : "5%",
      // transform: "translate(-50%, -50%)",
      // width: ["100%", "640px"],
      // height: ["100%", "360px"],
      marginTop: "20vh",
    },
    overlay: {
      zIndex: 10000,
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      // display: "flex",
      // justifyContent: "center",
      // alignItems: "center",
    },
  }

  // console.log(events)
  return (
    <i18nContext.Consumer>
      {t => (
        <Container>
          <Grid columns={[1, 2, 3, 4]} sx={{ mb: "120px" }}>
            {events.map(event => (
              <Flex
                sx={{
                  border: "1px solid black",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                }}
                key={event.id}
              >
                <Image
                  sx={{
                    width: ["100%", "100%", "334px"],
                    height: ["100%", "100%", "188px"],
                  }}
                  src={event.image.url}
                />

                <Box
                  sx={{
                    ml: 3,
                    mt: 2,

                    display: "flex",

                    alignItems: "center",
                    backgroundColor: event.primarycolor.hex,
                    width: "201px",
                    height: "20px",
                    borderRadius: "10px",
                    padding: "4px 15px 4px 15px",
                  }}
                >
                  <Text
                    sx={{
                      width: "100%",
                      color: "white",
                      textTransform: "uppercase",
                      fontSize: 0,
                    }}
                  >
                    {" "}
                    {event.channel.pageLink.title}
                  </Text>
                </Box>
                <Box sx={{ m: 3 }}>
                  <Heading
                    sx={{
                      color: "black ",
                      fontWeight: 500,
                    }}
                  >
                    {event.title}
                  </Heading>
                  <Heading sx={{ color: "black ", fontWeight: 400 }}>
                    {event.location}
                  </Heading>
                </Box>
                <Box sx={{ fontFamily: "alternative", fontSize: 3, m: 3 }}>
                  {" "}
                  <Text>{event.date}</Text>{" "}
                  <Text sx={{ fontWeight: 600 }}> &mdash; </Text>
                  <Text sx={{ fontWeight: 600 }}>{event.language}</Text>
                  <Box sx={{ mt: 3 }}>
                    <Button
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                        backgroundColor: "black",
                        padding: 3,
                      }}
                      onClick={() => openModal(event.embed)}
                    >
                      <Text
                        sx={{
                          ":hover": {
                            color: "primary",
                          },
                          color: "white",
                          fontSize: "14px",
                          textTransform: "uppercase",
                        }}
                      >
                        {t.watchvideo}
                      </Text>
                    </Button>
                  </Box>
                </Box>
              </Flex>
            ))}
          </Grid>
          <ReactModal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Video Modal"
            style={customStyles}
          >
            {/* <AspectRatio ratio={16 / 9} sx={{ width: "50%" }}> */}
            <Box>
              <Box
                sx={{
                  position: "relative",
                  height: "0",
                  paddingBottom: "56.25%",
                  iframe: {
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                  },
                }}
                dangerouslySetInnerHTML={{ __html: currentEmbed }}
              />
            </Box>
            {/* </AspectRatio> */}
          </ReactModal>
        </Container>
      )}
    </i18nContext.Consumer>
  )
}

export default PastEvents
