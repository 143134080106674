import React from "react"
import {
  Box,
  Flex,
  Grid,
  Heading,
  Text,
  Image,
  Container,
  Button,
} from "@theme-ui/components"
import Calendar from "../../../src/assets/images/calendar.svg"
import Pin from "../../../src/assets/images/pin.svg"
import { getEventPath } from "../../utils/path"
import { i18nContext } from "../../context/i18nContext"
import { InboundLink } from "../link"
const EventHero = ({ block }) => {
  // console.log(block)
  return (
    <i18nContext.Consumer>
      {t => (
        <Container sx={{ mb: "120px" }}>
          <Heading
            sx={{
              borderTop: "1px solid black",
              py: "30px",

              color: "black",
              fontSize: "60px",
            }}
          >
            {" "}
            {block.title}
          </Heading>
          <Grid
            columns={[1, 2, 2]}
            sx={{
              pb: "30px",

              borderBottom: "1px solid black",
              gap: 0,
              height: ["100%", "100%", "470px"],
              width: "100%",
            }}
          >
            <Flex
              sx={{
                p: 5,
                flexDirection: "column",
                gap: 5,
                backgroundColor: block.event.primaryColor.hex,
                height: "100%",
              }}
            >
              <Heading
                sx={{
                  color: "white",
                  fontSize: "34px",
                  width: "60%",
                }}
              >
                {" "}
                {block.event.title} &mdash;
              </Heading>
              <Grid columns={[1]} sx={{ gap: 0 }}>
                <Flex sx={{ gap: 3 }}>
                  <Image src={Calendar} />
                  <Box
                    dangerouslySetInnerHTML={{ __html: block.event.date }}
                    sx={{
                      pt: 2,
                      fontWeight: 500,
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      fontSize: "18px",
                      color: "white",
                    }}
                  />
                </Flex>

                <Flex
                  sx={{
                    gap: 3,

                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Image src={Pin} />
                  <Heading
                    dangerouslySetInnerHTML={{ __html: block.event.location }}
                    sx={{ fontSize: "18px", color: "white", fontWeight: 500 }}
                  />
                </Flex>
              </Grid>
              <Box
                sx={{
                  mt: 5,
                  mb: 0,
                  a: {
                    textDecoration: "none",
                  },
                }}
              >
                <InboundLink to={getEventPath(block.event)}>
                  <Button
                    sx={{
                      width: "137px",
                      height: "50px",
                      whiteSpace: "nowrap",
                      borderRadius: "25px",
                      padding: "20px ",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                      backgroundColor: "black",
                      color: block.event.primaryColor.hex,
                      padding: 2,
                    }}
                  >
                    <Text
                      sx={{
                        ":hover": {
                          color: "primary",
                        },
                        color: block.event.primaryColor.hex,
                        fontSize: "14px",
                      }}
                    >
                      Discover More
                    </Text>
                  </Button>
                </InboundLink>
              </Box>
            </Flex>
            <Box>
              <Image
                sx={{ width: "100%", height: "100%" }}
                src={block.event.heroImage.url}
              />
            </Box>
          </Grid>
        </Container>
      )}
    </i18nContext.Consumer>
  )
}

export default EventHero
