import React, { useState } from "react"
import {
  Box,
  Grid,
  Heading,
  Text,
  Container,
  Flex,
  AspectRatio,
} from "@theme-ui/components"
import { useBreakpointIndex } from "@theme-ui/match-media"
import { i18nContext } from "../../context/i18nContext"
import { InboundLink } from "../link"
import { GatsbyImage } from "gatsby-plugin-image"
import { getReportagePath } from "../../utils/path"

const ReportageCollection = ({ data }) => {
  const { reportages, title } = data
  const [showAll, setShowAll] = useState(false)
  const breakpointIndex = useBreakpointIndex()
  const itemsToShow = showAll ? reportages.length : breakpointIndex < 2 ? 2 : 3

  return (
    <i18nContext.Consumer>
      {t => (
        <>
          <Container sx={{ py: [4, 4, 6] }}>
            {title && (
              <Heading as="h1" variant="h1" sx={{ mb: [4], mt: [0] }}>
                {title}
              </Heading>
            )}
          </Container>
          <Container>
            <Grid columns={[1, 2, 3, 3]} sx={{ mb: "120px" }}>
              {reportages.slice(0, itemsToShow).map(reportage => (
                <Flex
                  key={reportage.id}
                  sx={{
                    border: "1px solid black",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                >
                  {reportage.heroImage && (
                    <AspectRatio
                      ratio={16 / 9}
                      sx={{
                        ".gatsby-image-wrapper": {
                          height: "100%",
                          width: "100%",
                          img: {
                            objectFit: "cover",
                          },
                        },
                      }}
                    >
                      <GatsbyImage
                        image={reportage.heroImage.gatsbyImageData}
                        alt={reportage.title}
                      />
                    </AspectRatio>
                  )}

                  <Box
                    sx={{
                      ml: 3,
                      mt: [3],
                      display: "inline-flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      backgroundColor:
                        reportage.channel?.pageLink?.slug || "primary",
                      borderRadius: "full",
                      px: 3,
                      py: 1,
                      alignSelf: "flex-start",
                    }}
                  >
                    <Text
                      sx={{
                        color: "white",
                        textTransform: "uppercase",
                        fontSize: 0,
                        whiteSpace: "nowrap",
                      }}
                    >
                      {reportage.channel?.pageLink?.title}
                    </Text>
                  </Box>

                  <Box sx={{ m: 3 }}>
                    <Heading
                      sx={{
                        color: "black",
                        fontWeight: 500,
                      }}
                    >
                      {reportage.title}
                    </Heading>
                  </Box>

                  <Box sx={{ fontFamily: "alternative", fontSize: 3, p: 3 }}>
                    {reportage.meta?.firstPublishedAt && (
                      <Text>
                        {t.publishedAt}: {reportage.meta.firstPublishedAt}
                      </Text>
                    )}
                    <Box sx={{ mt: 3 }}>
                      <InboundLink
                        to={getReportagePath(reportage)}
                        sx={{
                          display: "inline-flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "black",
                          borderRadius: "full",
                          px: 5,
                          py: 3,
                          textDecoration: "none",
                          alignSelf: "flex-start",
                          ":hover": {
                            "& > span": {
                              color: "primary",
                            },
                          },
                        }}
                      >
                        <Text
                          sx={{
                            color: "white",
                            fontSize: [2],
                            fontWeight: "bold",
                            textTransform: "uppercase",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {t.viewReportage}
                        </Text>
                      </InboundLink>
                    </Box>
                  </Box>
                </Flex>
              ))}
            </Grid>
            {reportages.length > itemsToShow && (
              <Box sx={{ textAlign: "center", mt: [4, 5] }}>
                <InboundLink
                  onClick={() => setShowAll(!showAll)}
                  sx={{
                    display: "inline-flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "black",
                    padding: 3,
                    textDecoration: "none",
                    cursor: "pointer",
                    ":hover": {
                      "& > span": {
                        color: "primary",
                      },
                    },
                  }}
                >
                  <Text
                    sx={{
                      color: "white",
                      fontSize: "14px",
                      textTransform: "uppercase",
                    }}
                  >
                    {showAll ? t.showLess : t.showMore}
                  </Text>
                </InboundLink>
              </Box>
            )}
          </Container>
        </>
      )}
    </i18nContext.Consumer>
  )
}

export default ReportageCollection
